<template>
    <div class="pageContol listWrap templateList">
        <div class="framePage">
            <div class="framePage-title">
                <span>
                    <em>当前位置：</em>
                    <a href="javascript:;">用户管理</a>
                    <i>></i>
                    <a
                        href="javascript:;"
                        class="cur-a"
                    >用户列表</a>
                </span>
            </div>
            <div class="framePage-body">
                <div class="operationControl">
                    <div class="searchbox">
                        <div
                            title="手机号"
                            class="searchboxItem"
                        >
                            <span class="itemLabel">手机号:</span>
                            <el-input
                                v-model="mobile"
                                type="text"
                                size="small"
                                style="width:auto"
                                placeholder="请输入手机号"
                                clearable
                            />
                        </div>
                        <!-- <div
                            title="标签"
                            class="searchboxItem"
                        >
                            <span class="itemLabel">标签:</span>
                            <el-input
                                v-model="labelId"
                                type="text"
                                size="small"
                                style="width:auto"
                                placeholder="请输入标签"
                            />
                        </div> -->
                        <div>
                            <el-button
                                class="bgc-bv"
                                @click="getData()"
                            >查询</el-button>
                        </div>
                    </div>
                </div>
                <div class="framePage-scroll">
                    <div class="ovy-a">
                        <el-table
                            ref="multipleTable"
                            :data="tableData"
                            :height="tableHeight"
                            size="small"
                            tooltip-effect="dark"
                            style="width: 100%"
                            :header-cell-style="tableHeader"
                        >
                            <el-table-column
                                label="序号"
                                align="center"
                                type="index"
                            />
                            <el-table-column
                                label="手机号"
                                align="center"
                                prop="mobile"
                                show-overflow-tooltip
                            />
                            <el-table-column
                                label="积分"
                                align="center"
                                prop="integral"
                                show-overflow-tooltip
                            />
                            <!-- <el-table-column
                                label="订单数量"
                                align="center"
                                prop="orderNum"
                                width="140px"
                            /> -->
                            <el-table-column
                                label="消费金额（元）"
                                align="center"
                                prop="orderSalary"
                            />
                            <el-table-column
                                label="注册时间"
                                align="center"
                                prop="createTime"
                            />
                            <!-- <el-table-column
                                label="标签"
                                align="center"
                                prop="createTime"
                            /> -->
                            <el-table-column
                                label="操作"
                                align="center"
                                width="160px"
                            >
                                <div
                                    slot-scope="scope"
                                    class="flexcc"
                                >
                                    <!-- <el-button
                                        size="mini"
                                        type="text"
                                        @click=""
                                    >订单明细</el-button> -->
                                    <el-button
                                        size="mini"
                                        type="text"
                                        @click="pointsInfo(scope.row)"
                                    >积分明细</el-button>
                                </div>
                            </el-table-column>
                            <Empty slot="empty" />
                        </el-table>
                    </div>
                </div>
                <PageNum
                    :apiData="apiData"
                    @sizeChange="sizeChange"
                    @getData="getData"
                />
            </div>
        </div>
        <el-dialog
            title="积分明细"
            style="min-height:500px"
            :visible.sync="dialogTableVisible"
            :before-close="tableClose"
        >
            <el-table
                :data="tableList"
                height="400px"
            >
                <el-table-column
                    label="积分分类"
                    align="center"
                >
                    <template slot-scope="scope">
                        {{$setDictionary('INTEGRAL_TYPE',scope.row.typeId)}}
                    </template>
                </el-table-column>
                <el-table-column
                    property="obtainIntegral"
                    label="积分"
                    align="center"
                ></el-table-column>
                <el-table-column
                    property="createTime"
                    label="时间"
                    align="center"
                ></el-table-column>
                <Empty slot="empty" />
            </el-table>
            <el-pagination
                background
                layout="total,prev, pager, next"
                :total="parseInt(tableTotal)"
                :pager-count="7"
                @current-change="getTable"
            >
            </el-pagination>
        </el-dialog>
    </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
export default {
    name: "miniUserList",
    components: {
        Empty,
        PageNum,
    },
    mixins: [List],
    data() {
        return {
            mobile: "",
            labelId: "", // 标签
            dialogTableVisible: false,
            tableList: [],
            tableTotal: 0,
        };
    },
    computed: {},
    methods: {
        getData(pageNum = 1) {
            const params = {
                pageNum: pageNum,
                pageSize: this.pageSize,
                mobile: this.mobile,
            };
            this.doFetch(
                {
                    url: "/biz/integral/selectMiniAppUserList",
                    params,
                    pageNum,
                },
                true,
                2
            );
        },
        // 编辑
        pointsInfo(row) {
            this.id = row.userId;
            this.dialogTableVisible = true;
            this.getTable();
        },
        getTable(pageNum1 = 1) {
            this.$post(
                "/biz/integral/selectMiniAppIntegralDetail",
                { id: this.id, pageNum: pageNum1, pageSize: 10 },
                3000,
                true,
                2
            ).then((res) => {
                ;
                this.tableTotal = res.data.total;
                this.tableList = res.data.list;
            });
        },
        tableClose(done) {
            this.tableList = [];
            this.tableTotal = 0;
            done();
        },
    },
};
</script>
<style lang="less"></style>
